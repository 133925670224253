import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function InputField(props) {
  const { errorText, noDisplay, ...rest } = props;
  const [field, meta] = useField(props);
  const display = !noDisplay;

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
    return null;
  };

  return (
    <>
      {
      display
      && (
      <TextField
        type="text"
        margin="normal"
        variant="outlined"
        error={meta.touched && meta.error && true}
        helperText={renderHelperText()}
        {...field}
        {...rest}
      />
      )
      }
    </>
  );
}

InputField.propTypes = {
  noDisplay: PropTypes.bool,
  errorText: PropTypes.string
};
