import axios from 'axios';
import util from './utilsRequest';

export default async function createInspectionRequestServ(payload) {
  const { headers, URL } = util;
  const config = {
    headers,
  };
  const data = {
    applicant: {
      identification: payload.docId,
      name: payload.firstName,
      sur_name: payload.surName,
      phone_num: payload.cellphone,
      email: payload.email,
      fax: payload.fax !== '' ? payload.fax : null,
      address_applicant: payload.address !== '' ? payload.address : null,
      confidential: payload.anonymous,
    },
    inspection: {
      id_inspections: payload.type,
      situation_problem: payload.problem,
      address_inspection: payload.inspectinoAddress
    },
    estate: {
      estate_number: payload.fincaNum !== '' ? payload.fincaNum : null,
      estate_owner: payload.fincaOwner,
      patent: payload.patent,
      patent_name: payload.patentName,
      authorization: payload.authorization
    },
  };
  try {
    const res = await axios.post(`${URL}/api/v1/total-inspection/`, data, config);
    if (res.status !== 200) throw new Error(res);
    return res.data;
  } catch (e) {
    throw new Error(e);
  }
}
