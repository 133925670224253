import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import requestInspectionInfoServ from 'src/services/requestInspectionInfoServ';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import RequestControl from '../components/RequestTracking/RequestControl';
import RequestDetailSection from '../components/RequestTracking/RequestDetailSection';

const RequestDetail = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    requestInspectionInfoServ(code)
      .then((response) => {
        if (response.ok) {
          setData(response.info);
          setLoading(false);
        } else {
          navigate('/404', { replace: true });
        }
      })
      .catch((err) => {
        console.log(`Error en la petición: \n${err}`);
        console.log(err);
      });
  }, []);

  return (

    <>
      <Helmet>
        <title>Trazabilidad Solicitud | Mora</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="md">
          {
            loading
              ? <SkeletonRequestTracking />
              : (
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    lg={12}
                  >
                    <RequestControl status={data.status} />

                  </Grid>
                  <Grid
                    item
                    lg={12}
                  >
                    <RequestDetailSection data={data} />

                  </Grid>
                </Grid>
              )
          }
        </Container>
      </Box>
    </>
  );
};

function SkeletonRequestTracking() {
  const sx = { bgcolor: '#95bcff2A', borderRadius: '10px' };

  return (
    <Stack spacing={2}>
      <Skeleton sx={sx} variant="rectangular" height={200} />
      <Skeleton sx={sx} variant="rectangular" height="50vh" />
    </Stack>
  );
}

export default RequestDetail;
