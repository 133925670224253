import { Helmet } from 'react-helmet';
import useRequests from 'src/hooks/useRequests';
import { Box, Container, Grid } from '@material-ui/core';
import TYPES from 'src/components/RequestIcon/TYPES';
import RequestsTables from '../components/RequestsTables/RequestsTables';
import RequestHeader from '../components/HeaderRequestsTable/RequestsHeader';

const CompletedReq = () => {
  const { requests, ...pagination } = useRequests({ requestType: 5 });
  return (
    <>
      <Helmet>
        <title>Solicitudes Completadas | Mora</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >

        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <RequestHeader
                type={TYPES[4]}
                total={36}
                growth={12}
              />
            </Grid>
            <Grid
              item
              lg={12}
            >
              <RequestsTables pagination={pagination} type={TYPES[4]} requestList={requests} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CompletedReq;
