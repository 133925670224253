import axios from 'axios';
import util from './utilsRequest';

export default async function registerService({
  username,
  firstName,
  lastName,
  password,
}, token) {
  const { getHeaderWithAuth, URL } = util;
  const headers = getHeaderWithAuth(token);
  const config = {
    headers,
  };
  const data = {
    user: username,
    id_rol: 1,
    name: firstName,
    sur_name: lastName,
    password,
    enabled: true
  };

  try {
    const res = await axios.post(`${URL}/api/v1/security/users/register/`, data, config);
    if (res.data.status_code === 400) return { ok: false, info: 'El usuario ya existe' };
    if (res.data.status_code !== 201) throw new Error(res);
    return { ok: true, info: 'Usuario creado Exitosamente' };
  } catch (e) {
    if (e.response.status === 0) return { ok: false, info: 'Error en el servidor' };
    throw new Error(e);
  }
}
