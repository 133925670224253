import React from 'react';
import PropTypes from 'prop-types';
import RequestDataInput from '../InspectionRequest/Forms/ConfirmationFormSections/RequestDataInput';

const headerTranslate = {
  ref: ' Nro Trámite',
  incommingDate: ' Fecha Ingreso',
  petitioner: ' Solicitante',
  receivedBy: 'Recibido por',
  inspectionType: 'Tipo de trámite',
  approvedDate: 'Fecha de admisión',
  approvedBy: 'Admitido por',
  rejectedDate: 'Fecha de Rechazo',
  rejectedBy: 'Rechazado por',
  status: 'Estado',
  resolution: 'Resolución'
};

const ConfirmationUserForm = ({ formValues }) => (
  <>

    {formValues.ref && <RequestDataInput name={headerTranslate.ref} data={formValues.ref} key="ref" />}
    {formValues.incommingDate && (
    <RequestDataInput
      name={headerTranslate.incommingDate}
      data={formValues.incommingDate}
      key="incommingDate"
    />
    )}
    {formValues.resolution !== 'WITHOUT RESOLUTION' && <RequestDataInput name={headerTranslate.resolution} data={formValues.resolution} key="resolution" />}

  </>
);

export default ConfirmationUserForm;

ConfirmationUserForm.propTypes = {
  formValues: PropTypes.object,
};
