import axios from 'axios';
import util from './utilsRequest';

export default async function downloadInspectionFileServ(token) {
  const { getHeaderWithAuth, URL } = util;
  const headers = getHeaderWithAuth(token);
  const config = {
    headers,
    responseType: 'blob'
  };
  try {
    const res = await axios.get(
      `${URL}/api/v1/administrative/admin/data/download-all/`,
      config
    );
    if (res.status !== 200) throw new Error(res);
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'inspections.csv'); // or any other extension
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    throw new Error(e);
  }
}
