import {
  Box,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Stepper from './Stepper';
// import dataExample from 'src/utils/dataExample';

const RequestControl = (props) => {
  const { status } = { ...props };
  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >

          <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
            mb={4}
          >
            Estado de Solicitud
          </Typography>
          <Stepper step={status} />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default RequestControl;

RequestControl.propTypes = {
  status: PropTypes.string,
};
