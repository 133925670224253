import React from 'react';
import PropTypes from 'prop-types';
import GradingIcon from '@mui/icons-material/Grading';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TYPES from './TYPES';

function RequestIcon({
  type, height, width, ...props
}) {
  if (type === TYPES[0]) return <ArrowCircleRightOutlinedIcon {...props} sx={{ color: 'text.contrast', height, width }} />;
  if (type === TYPES[1]) return <GradingIcon {...props} sx={{ color: 'request.green', height, width }} />;
  if (type === TYPES[2]) return <VisibilityOutlinedIcon {...props} sx={{ color: 'request.orange', height, width }} />;
  if (type === TYPES[3]) return <HighlightOffSharpIcon {...props} sx={{ color: 'request.red', height, width }} />;
  if (type === TYPES[4]) return <DoneAllIcon {...props} sx={{ color: 'text.contrast', height, width }} />;
}
export default RequestIcon;

RequestIcon.propTypes = {
  type: PropTypes.oneOf(TYPES),
  height: PropTypes.number,
  width: PropTypes.number,
};
