// import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // TableSortLabel,
  // Tooltip
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TYPES from '../RequestIcon/TYPES';
/*
  id: PropTypes.string,
  date_created_inspection: PropTypes.string,
  registered_name_user: PropTypes.string,
  receivedBy: PropTypes.string,
  inspection_type: PropTypes.string,
  approvedDate: PropTypes.string,
  approvedBy: PropTypes.string,
  rejectedDate: PropTypes.string,
*/

const headerTranslate = {
  inspection_id: ' ID',
  date_created_inspection: ' Fecha Ingreso',
  last_status_date: 'Ultimo Cambio',
  applicant_name: ' Solicitante',
  receivedBy: 'Recibido por',
  inspection_type: 'Tipo de trámite',
  approvedDate: 'Fecha de admisión',
  approvedBy: 'Admitido por',
  rejectedDate: 'Fecha de Rechazo',
  rejectedBy: 'Rechazado por',
  status: 'Estado'
};

const RequestsTables = ({
  type, requestList, pagination, ...props
}) => {
  let currentheaders = [];
  const navigate = useNavigate();

  const viewDetails = (id) => {
    navigate(`/app/sol-detalles/${id}`);
  };

  const TableIncommingRequests = () => {
    currentheaders = [
      'inspection_id',
      'date_created_inspection',
      'last_status_date',
      'applicant_name',
      'inspection_type',
    ];
  };

  const TableApprovedRequests = () => {
    currentheaders = [
      'inspection_id',
      'date_created_inspection',
      'last_status_date',
      'applicant_name',
      'inspection_type',
    ];
  };

  const TableInspectionRequests = () => {
    currentheaders = [
      'inspection_id',
      'date_created_inspection',
      'last_status_date',
      'applicant_name',
      'inspection_type',
    ];
  };

  const TableRejectedRequests = () => {
    currentheaders = [
      'inspection_id',
      'date_created_inspection',
      'last_status_date',
      'applicant_name',
      'inspection_type',
    ];
  };

  const TableCompletedRequests = () => {
    currentheaders = [
      'inspection_id',
      'date_created_inspection',
      'last_status_date',
      'applicant_name',
      'inspection_type',
    ];
  };

  if (type === TYPES[0]) TableIncommingRequests();
  if (type === TYPES[1]) TableApprovedRequests();
  if (type === TYPES[2]) TableInspectionRequests();
  if (type === TYPES[3]) TableRejectedRequests();
  if (type === TYPES[4]) TableCompletedRequests();

  const requests = requestList;

  const renderHeader = () => currentheaders.map((header) => (
    <TableCell key={header}>
      {headerTranslate[header]}
    </TableCell>
  ));

  const renderRequestRow = (request) => {
    const cellList = currentheaders.map((header) => {
      if (header === 'status') {
        return (
          <TableCell key={`${request.inspection_id}-${header}`}>
            <Chip
              color="primary"
              label={request[header]}
              size="small"
            />
          </TableCell>
        );
      }
      return <TableCell key={`${request.inspection_id}-${header}`}>{request[header]}</TableCell>;
    });
    return (
      <TableRow hover sx={{ cursor: 'pointer' }} key={request.inspection_id} onClick={() => viewDetails(request.inspection_id)}>
        {cellList}
      </TableRow>
    );
  };

  return (
    <Card {...props}>
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {renderHeader()}
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.map(renderRequestRow)}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          size="small"
          variant="text"
          disabled={!pagination.prevPageEnabled}
          onClick={pagination.previousPage}
        >
          Anterior
        </Button>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          {pagination.page}
        </Button>
        <Button
          color="primary"
          endIcon={<ArrowForwardIosIcon />}
          size="small"
          variant="text"
          disabled={!pagination.nextPageEnabled}
          onClick={pagination.nextPage}
        >
          Siguiente
        </Button>
      </Box>
    </Card>
  );
};
export default RequestsTables;

RequestsTables.propTypes = {
  type: PropTypes.oneOf(TYPES),
  requestList: PropTypes.array,
  pagination: PropTypes.object
};
