import axios from 'axios';
import util from './utilsRequest';

export default async function getRequestInspectionInfoServ(type, page, token) {
  const { getHeaderWithAuth, URL } = util;
  const headers = getHeaderWithAuth(token);
  const config = {
    headers,
  };
  try {
    const res = await axios.get(
      `${URL}/api/v1/administrative/admin/data/inspections-type/${type}?page=${page}&size=10`,
      config
    );
    if (res.status !== 200) throw new Error(res);
    return res.data;
  } catch (e) {
    throw new Error(e);
  }
}
