import React from 'react';
import {
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RequestDataInput from './RequestDataInput';

const ConfirmationInspectionForm = ({ formValues }) => (
  <>
    <Typography style={{ marginTop: 20 }} variant="h4" gutterBottom>
      Información de la Inspección
    </Typography>

    {formValues.type !== 100 && <RequestDataInput name="Tipo de Inspección" data={formValues.type} key="tipo" />}
    {formValues.type === 100 && <RequestDataInput name="Tipo de Inspección" data={formValues.specialType} key="tipo esp" />}
    {formValues.problem && <RequestDataInput extended name="Descripción del Problema" data={formValues.problem} key="problema" />}
    {formValues.inspectinoAddress && <RequestDataInput extended name="Dirección del lugar de inspección" data={formValues.inspectinoAddress} key="dirreción Ins" />}

    {
        formValues.type === 'Gestión Ambiental'
        && <Alert severity="warning">Se tratara de comunicar con el solicitante en caso de existir un problema con los documentos enviados.</Alert>
    }
    {
        formValues.type === 'Rec.Residuos Sólidos'
        && <Alert severity="warning">Se tratara de comunicar con el solicitante en caso de existir un problema con los documentos enviados.</Alert>
    }

    {formValues.fincaNum !== 0 && formValues.fincaNum && <RequestDataInput name="Número de finca" data={formValues.fincaNum} key="fincaNum" />}
    {formValues.fincaOwner && formValues.fincaOwner !== 'DOES NOT HAVE OWNER' && <RequestDataInput name="Propietario de finca" data={formValues.fincaOwner} key="fincaOwner" />}
    {formValues.patent && formValues.patent !== 'DOES NOT HAVE PATENT NUMBER' && <RequestDataInput name="Patente" data={formValues.patent} key="patent" />}
    {formValues.patentName && formValues.patentName !== 'DOES NOT HAVE PATENT NAME' && <RequestDataInput name="Nombre de patente" data={formValues.patentName} key="patentName" />}
    {
      formValues.file && (
        <Box>
          <Typography
            color="textPrimary"
            variant="h6"
            style={{ marginRight: 5, fontWeight: 500 }}
          >
            Archivo de requisitos
            :
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: '#f4f6f8',
              p: '0.5em',
              borderRadius: '4px',
              maxWidth: '20em',
              overflow: 'hidden'
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                border: '2px solid #5664d2',
                borderRadius: '4px',
                color: '#5664d2',
                margin: '0px 7px',
                width: '40px'
              }}
            >
              <PictureAsPdfIcon />
            </Box>
            <Typography variant="h6" color="text.secondary">
              {formValues.file.name}
            </Typography>
          </Box>
        </Box>
      )
}
  </>
);

export default ConfirmationInspectionForm;

ConfirmationInspectionForm.propTypes = {
  formValues: PropTypes.object,
};
