import { Helmet } from 'react-helmet';
import {
  Grid,
} from '@material-ui/core';
import Checkout from 'src/components/InspectionRequest/Checkout';
import CreateRequestInfo from 'src/components/HomeMora/CreateRequestInfo';

const MoraRequest = () => (
  <>
    <Helmet>
      <title>Solicitud | Mora</title>
    </Helmet>
    <main>

      <Grid
        container
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <CreateRequestInfo inverted />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <Checkout />
        </Grid>
      </Grid>
    </main>
  </>
);

export default MoraRequest;
