import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  TextField,
  Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useState } from 'react';

// MM-cc63b066-b24f-4814-a9ca-17eb50204466
export default function RequestInfo() {
  const [code, setCode] = useState('');
  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Box sx={{ mb: 3 }}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Información de solicitud de inspección.
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Agrega el número de trámite para encontrar tu solicitud de inspección.
            </Typography>
            <TextField
              fullWidth
              label="número de trámite"
              placeholder="**-********-****-****-****-********"
              margin="normal"
              type="text"
              variant="outlined"
              size="small"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </Box>
          <Button
            disabled={!code}
            variant="contained"
            component={Link}
            to={`/solicitud-track/${code}`}
          >
            Enviar
          </Button>
        </Paper>
      </Container>
    </>
  );
}
