import React from 'react';
import { Stack } from '@mui/material';
import {
  Box,
  Container,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PreviewIcon from '@mui/icons-material/Preview';
import RequestHomeButton from './RequestHomeButton';

function ViewRequestInfo({ inverted }) {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          py: '6em'
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              pb: 3,
            }}
            display="flex"
            justifyContent="center"
          >
            <PreviewIcon
              sx={{
                width: '100px',
                height: '100px',
                color: '#5664d2'
              }}
            />
          </Box>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Ver Solicitudes de Inspección
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Obtén actualizaciones  o envia documentos necesarios de tu solicitud a través del número de trámite.
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <RequestHomeButton
              type="VIEW"
              inverted={inverted}
            />
          </Stack>
        </Container>
      </Box>

    </>
  );
}

export default ViewRequestInfo;

ViewRequestInfo.propTypes = {
  inverted: PropTypes.bool
};
