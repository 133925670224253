export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'Nombre del solicitante*',
      requiredErrorMsg: 'El nombre es obligatorio.'
    },
    surName: {
      name: 'surName',
      label: 'Apellidos del solicitante*',
      requiredErrorMsg: 'Los Apellidos son obligatorio.'
    },
    docId: {
      name: 'docId',
      label: 'Documento de identificación*',
      requiredErrorMsg: 'La identificación es obligatoria.'
    },
    cellphone: {
      name: 'cellphone',
      label: 'Número de Teléfono*',
      requiredErrorMsg: 'El número de celular es obligatorio'
    },
    email: {
      name: 'email',
      label: 'Correo electrónico*',
      requiredErrorMsg: 'El correo electrónico es obligatorio',
      invalidErrorMsg: 'El correo debe ser válido'
    },
    fax: {
      name: 'fax',
      label: 'Fax',
    },
    address: {
      name: 'address',
      label: 'Dirección del solicitante'
    },
    anonymous: {
      name: 'anonymous',
      label: 'Solicitud Confidencial'
    },
    type: {
      name: 'type',
      label: 'Tipo de Inspección',
      requiredErrorMsg: 'El tipo de Inspección es obligatorio',
    },
    specialType: {
      name: 'specialType',
      label: 'Otro...',
      requiredErrorMsg: 'El tipo de Inspección es obligatorio',
    },
    problem: {
      name: 'problem',
      label: 'Situación o Problema',
      placeholder: 'Indique la situación o problema por lo cual solicita la inspección.',
      requiredErrorMsg: 'La descripción del problema es obligatoria',
    },
    inspectinoAddress: {
      name: 'inspectinoAddress',
      label: 'Dirección del lugar de la inspección',
      placeholder: 'Dirección exacta del lugar donde se debe realizar la inspección.',
      requiredErrorMsg: 'La dirección es obligatoria',
    },
    fincaNum: {
      name: 'fincaNum',
      label: 'Número de Finca',
      placeholder: 'Número de Finca.',
      requiredErrorMsg: 'El campo es obligatorio',
    },
    fincaOwner: {
      name: 'fincaOwner',
      label: 'Propietario de la Finca',
      requiredErrorMsg: 'El campo es obligatorio',
    },
    file: {
      name: 'file',
      label: 'Archivo de requisitos',
      requiredErrorMsg: 'Subir el archivo es obligatorio',
    },
    patent: {
      name: 'patent',
      label: 'Patente',
      requiredErrorMsg: 'El campo es obligatorio',
    },
    patentName: {
      name: 'patentName',
      label: 'Nombre de la Patente',
      requiredErrorMsg: 'El campo es obligatorio',
    },
    authorization: {
      name: 'authorization',
      label: 'Autorizó el ingreso a la propiedad privada',
      requiredErrorMsg: 'El campo es obligatorio',
    },

  }
};
