import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';
import ConfirmationUserForm from 'src/components/InspectionRequest/Forms/ConfirmationFormSections/ConfirmationUserForm';
import ConfirmationInspectionForm from 'src/components/InspectionRequest/Forms/ConfirmationFormSections/ConfirmationInspectionForm';
import RequestControlInfo from 'src/components/RequestTracking/RequestControlInfo';

const AccountProfileDetails = (props) => {
  const { data } = { ...props };
  const req = {
    firstName: data.name,
    surName: data.sur_name,
    docId: data.identification,
    cellphone: data.phone_num,
    email: data.email,
    fax: data.fax,
    address: data.address_applicant,
    type: data.inspection_type,
    problem: data.situation_problem,
    inspectinoAddress: data.address_inspection,
    ref: data.id_confirmation_code,
    incommingDate: data.created_timestamp,
    resolution: data.resolution
  };

  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          title="Detalles de la Solicitud"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{
              padding: 5,
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                marginTop: 2,
              }}
            >
              <ConfirmationUserForm formValues={req} />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <ConfirmationInspectionForm formValues={req} />
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <CardHeader
          title="Control de la Solicitud"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{
              padding: 5,
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            <Grid
              item
              xl={12}
              sx={{
                marginTop: 2,
              }}
            >
              <RequestControlInfo formValues={req} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
