import axios from 'axios';
import util from './utilsRequest';

export default async function getDashboardInfoAllMonthsServ(token) {
  const { getHeaderWithAuth, URL } = util;
  const headers = getHeaderWithAuth(token);
  const config = {
    headers,
  };
  try {
    const res = await axios.get(
      `${URL}/api/v1/administrative/dashboard/data/inspection-by-month-total/`,
      config
    );
    if (res.status !== 200) throw new Error(res);
    return { ok: true, info: res.data };
  } catch (e) {
    throw new Error(e);
  }
}
