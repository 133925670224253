import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  //  Grid,
  //  Link,
  TextField,
  Typography
} from '@material-ui/core';
import MoraLogo from 'src/icons/MoraLogo';
import Alert from '@mui/material/Alert';
import useAuthUser from 'src/hooks/useAuthUser';

const Login = () => {
  const {
    isLoginLoading, hasLoginError, login, isLogged
  } = useAuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogged) {
      navigate('/app/dashboard');
    }
  }, [isLogged]);

  return (
    <>
      <Helmet>
        <title>Login | Mora</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              pb: 8,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <MoraLogo
              type="primary"
              width="395"
              height="210"
            />
          </Box>
          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required('El usuario es necesario'),
              password: Yup.string().max(255).required('la contraseña es necesaria')
            })}
            onSubmit={(values) => {
              login(values.username, values.password);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Inicio de Sesión
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Accede a la plataforma de administración
                  </Typography>
                  {hasLoginError ? <Alert style={{ marginTop: 30, marginBottom: 10 }} severity="error">Credenciales Incorrectas</Alert> : ''}
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Nombre de Usuario"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="username"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isLoginLoading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Accede Ahora
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
