import axios from 'axios';
import util from './utilsRequest';

export default async function updateRequestInspectorServ(id, name, token) {
  const ENDPOINT = '/api/v1/administrative/admin/data/update-inspector-name/';
  const { getHeaderWithAuth, URL } = util;
  const headers = getHeaderWithAuth(token);
  const config = {
    headers,
  };
  const params = `?inspection_id=${id}&name_of_inspector=${name}`;

  try {
    const res = await axios.put(`${URL}${ENDPOINT}${params}`, {}, config);
    if (res.status !== 200) throw new Error(res);
    return { ok: true, info: res.data };
  } catch (e) {
    throw new Error(e);
  }
}
