import axios from 'axios';
import util from './utilsRequest';
import updateRequestInspectorServ from './updateRequestInspectorServ';

export default async function updateRequestStatusServ(id, status, token, name, resolution) {
  const ENDPOINT = '/api/v1/administrative/admin/data/update-inspection-status/';
  const { getHeaderWithAuth, URL } = util;
  const headers = getHeaderWithAuth(token);
  const config = {
    headers,
  };
  const params = `?inspection_id=${id}&new_inspection_status=${status}&resolution=${resolution}`;

  try {
    const res = await axios.put(`${URL}${ENDPOINT}${params}`, {}, config);
    if (res.status !== 200) throw new Error(res);
    if (res.status === 200) {
      if (status === 3) {
        const response = await updateRequestInspectorServ(id, name, token);
        if (!response.ok) throw new Error(res);
      }
    }
    return { ok: true, info: status };
  } catch (e) {
    throw new Error(e);
  }
}
