import { Stack } from '@mui/material';
import {
  Box,
  Container,
  Typography
} from '@material-ui/core';
// import MoraLogo from 'src/icons/MoraLogo';
import PropTypes from 'prop-types';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RequestHomeButton from './RequestHomeButton';

export default function CreateRequestInfo({ inverted }) {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          py: '5em'
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              pb: 3,
            }}
            display="flex"
            justifyContent="center"
          >
            <ForwardToInboxIcon
              sx={{
                width: '100px',
                height: '100px',
                color: '#5664d2'
              }}
            />
          </Box>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Solicitudes de Inspecciones
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Realiza una solicitud rellenando el formulario que se muestra a continuación.
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" component="span">
            Una vez se haya realizado la solicitud se le dará un
            <Box fontWeight="fontWeightMedium" display="inline"> número de trámite </Box>
            con el cual podrá ver el estado de la solicitud o enviar documentos adicionales de ser necesario.
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <RequestHomeButton
              type="CREATE"
              inverted={inverted}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
}

CreateRequestInfo.propTypes = {
  inverted: PropTypes.bool
};
