const typesList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 100];

const typeOption = [
  {
    value: 1,
    label: 'Denuncias de Construcciones',
    desc: 'Solicitud de revisión de los Permisos de Construcción de las obras en curso del cantón, haciendo constar de si cuentan con la debida licencia, revisión del cumplimiento de los retiros y así como de la legislación vinculante.',
    req: 2,
  },
  {
    value: 2,
    label: 'Levantamiento de Gravamen de Construcción',
    desc: 'Solicitud para la eliminación de las clausuras o actos preventivos de construcción, de las diferentes plataformas digitales de la Municipalidad. Para obras debidamente reguladas y construidas.',
    req: 2,
  },
  {
    value: 3,
    label: 'Cobro de basura',
    desc: 'Solicitud de inspección para estudio de cobro adicional en casos de unidades habitacionales o locales comerciales inexistentes o cobrados demás.',
    req: 7,
  },
  {
    value: 4,
    label: 'Pesaje de basura',
    desc: 'Solicitud de inspección para estudio de peso de los residuos sólidos para re-clasificación de categoría tarifaría de cobro de residuos sólidos.',
    req: 8,
  },
  {
    value: 5,
    label: 'Contraloría ambiental',
    desc: 'Solicitudes relacionadas a denuncias por invasión de área de protección de ríos y nacientes, tenencia ilegal de animales silvestres, contaminación del agua, aire o suelo, caza ilegal, tala ilegal de árboles.',
    req: 2,
  },
  {
    value: 6,
    label: 'Servicios de recolección de residuos sólidos',
    desc: 'Solicitud para ampliar cobertura del servicio, para verificar pesaje para estudio de tarifa comercial, para denunciar botaderos a cielo abierto, interponer quejas por no recolección.',
    req: 2,
  },
  {
    value: 7,
    label: 'Solicitud de inspección de aceras',
    desc: 'Realiza una inspección del estado de la acera, cuando considere que pone en riesgo su derecho de transitar de forma adecuada por la misma.',
    req: 2,
  },
  {
    value: 8,
    label: 'Manejo de aguas pluviales',
    desc: 'Se realiza una inspección que indique una supuesta deficiencia en el manejo de aguas pluviales, ya sea cordón de caño, cuneta, alcantarillado pluvial y/o pasos transversales, hacia o desde su propiedad o de un tercero.',
    req: 2,
  },
  {
    value: 9,
    label: 'Invasión de vía pública',
    desc: 'Se considera que algún lindero está por fuera de la línea de propiedad, es decir invadiendo la vía pública, por lo tanto solicita la inspección para corroborar o descartar dicha sospecha.',
    req: 2,
  },
  {
    value: 10,
    label: 'Estado de la superficie de ruedo',
    desc: 'El usuario puede solicitar una inspección cuando considere que el estado de la calzada (calle) es inadecuado para el traslado de vehículos, bicicletas,  motocicletas, transporte público y/o personal médico.',
    req: 2,
  },
  {
    value: 11,
    label: 'Ley 7600',
    desc: 'El usuario podrá realizar una solicitud de inspección cuando considere que no se cumple con la ley 7600, poniendo en riesgo los derechos y la igualdad de condiciones de todos los usuarios.',
    req: 2,
  },
  {
    value: 12,
    label: 'Solicitud de descuaje',
    desc: 'El usuario podrá solicitar inspección para determinar si la corta de ramas de una propiedad corresponde al usuario como lo establece la ley general de caminos o por alguna situación especial corresponde a la municipalidad.',
    req: 2,
  },
  {
    value: 100,
    label: 'Otro',
    desc: '',
    req: 2,
  },
];

const requirements = [
  'Situación o problema',
  'Dirección del lugar de la inspección',
  'Número de Finca',
  'Nombre del propietario de la finca',
  'Copia de la cédula del propietario o representante legal',
  'Personería jurídica vigente (en caso de ser persona jurídica)',
  'Autorización de ingreso a la propiedad privada',
  'Patente y su nombre comercial',
];

export default { typeOption, requirements, typesList };
