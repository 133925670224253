import PropTypes from 'prop-types';

const MoraLogo = ({ type, height, width }) => {
  if (type === 'secondary') {
    return <img width={width} height={height} src="/static/images/munis/simple_muni_logo_header.png" alt="mora logo alt" />;
  }
  return <img width={width} height={height} src="/static/images/munis/simple_muni_logo.jpg" alt="mora logo" />;
};

export default MoraLogo;

MoraLogo.propTypes = {
  type: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};
