import axios from 'axios';
import util from './utilsRequest';

const USER_NOT_EXIST = 0;
const INCORRECT_CREDENTIALS = 1;

export default async function createInspectionRequestServ(username, password) {
  const { headers, URL } = util;
  const config = {
    headers,
  };

  const formData = new FormData();

  // const params = { username, password };
  formData.append('username', username);
  formData.append('password', password);
  try {
    const res = await axios.post(`${URL}/api/v1/security/token/`, formData, config);
    if (res.status !== 200) throw new Error(res);
    return { ok: true, info: res.data };
  } catch (e) {
    if (e.response.status === 404) return { ok: false, info: USER_NOT_EXIST };
    if (e.response.status === 401) return { ok: false, info: INCORRECT_CREDENTIALS };
    throw new Error(e);
  }
}
