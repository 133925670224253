import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import useAuthUser from 'src/hooks/useAuthUser';
import getDashboardInfoServ from 'src/services/getDashboardInfoServ';
import RequestGadget from '../components/dashboard/MoraGadgets/RequestGadget';
import AcceptedRequestGadget from '../components/dashboard/MoraGadgets/AcceptedRequestGadget';
import InspectedRequestGadget from '../components/dashboard/MoraGadgets/InspectedRequestGadget';
import RejectedRequestGadget from '../components/dashboard/MoraGadgets/RejectedRequestGadget';
import RequestsReview from '../components/dashboard/RequestsReview';
import RequestPie from '../components/dashboard/RequestPie';

const status = {
  incoming: 'Entrantes',
  on_inspection: 'En inspeccion',
  approved: 'Aprobadas',
  rejected: 'No aprobada',
};

const Dashboard = () => {
  const [headerData, setHeaderData] = useState([]);
  const { token } = useAuthUser();
  useEffect(() => {
    getDashboardInfoServ(token).then((response) => {
      if (response.ok) { setHeaderData(response.info); }
    });
  }, []);

  const headerDataFilter = (name) => {
    if (headerData.length > 0) {
      const header = headerData.filter((inspection) => inspection.status === name);
      if (header.length > 0) { return header[0]; }
    }
    return { quantity: 0 };
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Mora</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
          />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RequestGadget data={headerDataFilter(status.incoming)} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <AcceptedRequestGadget data={headerDataFilter(status.approved)} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <InspectedRequestGadget data={headerDataFilter(status.on_inspection)} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <RejectedRequestGadget data={headerDataFilter(status.rejected)} />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <RequestsReview />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <RequestPie sx={{ height: '100%' }} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
