import MoraLogo from 'src/icons/MoraLogo';

const Logo = () => (
  <MoraLogo
    type="secondary"
    width="40"
    height="40"
  />
);

export default Logo;
