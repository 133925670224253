import React from 'react';
import {
  Box,
  Container,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import ConfirmationUserForm from './ConfirmationFormSections/ConfirmationUserForm';
import ConfirmationInspectionForm from './ConfirmationFormSections/ConfirmationInspectionForm';

export default function ConfirmationForm() {
  const { values: formValues } = useFormikContext();

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        pt: 4,
      }}
    >
      <Container maxWidth="sm">
        <ConfirmationUserForm formValues={formValues} />
        <ConfirmationInspectionForm formValues={formValues} />

      </Container>
    </Box>
  );
}

const RequestDataInput = ({ name, data }) => (
  <div style={{ display: 'flex', margin: '5px 0px' }}>
    <Typography
      color="textPrimary"
      variant="h6"
      style={{ marginRight: 5 }}
    >
      {name}
    </Typography>
    <Typography
      color="textSecondary"
      gutterBottom
      variant="body2"
    >
      {data}
    </Typography>
  </div>
);

RequestDataInput.propTypes = {
  name: PropTypes.string,
  data: PropTypes.any
};
