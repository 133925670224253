import React from 'react';
import { useField } from 'formik';
import {
  Box,
  Container,
  Typography,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import InputField from 'src/components/FormFiled.jsx/InputField';
import CheckBoxField from 'src/components/FormFiled.jsx/CheckBoxField';
// import { ListItemText, ListItem, List } from '@mui/material';
import inspections from 'src/utils/inspections';
// import UploadFIle from './ConfirmationFormSections/UploadFIle';

export default function InspectionDataForm({ formField }) {
  // const formikProps = useFormikContext();
  // requirements
  const { typeOption } = inspections;
  const {
    type,
    problem,
    fincaNum,
    inspectinoAddress,
    fincaOwner,
    patent,
    patentName,
    authorization
  } = formField;

  const [typeField] = useField(type.name);
  const getInspectionType = () => typeOption.filter((t) => typeField.value === t.value);

  const { req } = getInspectionType()[0];
  const midReq = req > 2;
  const highReq = req > 7;

  /*
  const renderRequirements = () => {
    const requireList = requirements.slice(4, 6);
    return requireList.map((r, i) => (
      <ListItem key={r} sx={{ p: 0, pl: 1 }}>
        <ListItemText
          primary={(
            <Typography variant="listItem" color="text.primary">
              {i + 1}
              {' '}
              -
              {' '}
              {r}
            </Typography>
              )}
        />
      </ListItem>
    ));
  }; */

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        pt: 2,
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="h5" color="text.contrast" sx={{ mt: 1 }}>
          Información de Inspección
        </Typography>
        <InputField
          fullWidth
          margin="normal"
          name={problem.name}
          label={problem.label}
          placeholder={problem.placeholder}
          variant="outlined"
          rows={2}
          multiline
        />
        <InputField
          fullWidth
          margin="normal"
          name={inspectinoAddress.name}
          label={inspectinoAddress.label}
          placeholder={inspectinoAddress.placeholder}
          variant="outlined"
          rows={2}
          multiline
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputField
              fullWidth
              name={fincaNum.name}
              label={fincaNum.label}
              noDisplay={!midReq}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              fullWidth
              name={fincaOwner.name}
              label={fincaOwner.label}
              noDisplay={!midReq}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputField
              fullWidth
              name={patent.name}
              label={patent.label}
              noDisplay={!highReq}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              fullWidth
              name={patentName.name}
              label={patentName.label}
              noDisplay={!highReq}
            />
          </Grid>
        </Grid>

        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
        />
        {
            req > 2
            && (
            <>
              { /*
                <Typography variant="h5" color="text.contrast" sx={{ mt: 1 }}>
                  Subir documento en pdf con los siguientes requisitos:
                </Typography>

                <List>
                  {renderRequirements()}
                </List>

                <UploadFIle setFieldValue={formikProps.setFieldValue} /> */
              }

              <Typography variant="h5" color="text.contrast" sx={{ mt: 2 }}>
                Autorización
              </Typography>
              <CheckBoxField
                name={authorization.name}
                label={authorization.label}
              />

            </>
            )

        }

      </Container>
    </Box>
  );
}

InspectionDataForm.propTypes = {
  formField: PropTypes.object,
};
