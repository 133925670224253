import { Link as RouterLink, Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  // Hidden,
  Button,
  Toolbar
} from '@material-ui/core';
import Logo from './Logo';

const MainNavbar = (props) => (
  <AppBar
    elevation={0}
    {...props}
  >
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to="/inicio">
        <Logo />
      </RouterLink>
      <Box sx={{ flexGrow: 1 }} />

      <Button
        color="inherit"
        size="large"
        component={Link}
        to="/inicio"
      >
        Inicio
      </Button>
      <Button
        color="inherit"
        size="large"
        component={Link}
        to="/login"
      >
        Acceder
      </Button>

    </Toolbar>
  </AppBar>
);

export default MainNavbar;
