import React, { useState } from 'react';

const Context = React.createContext({});

export function AuthContextProvider(props) {
  const { children } = { ...props };
  const [token, setToken] = useState(
    () => window.sessionStorage.getItem('token')
  );

  return (
    <Context.Provider value={{
      token,
      setToken
    }}
    >
      {children}
    </Context.Provider>
  );
}

export default { AuthContextProvider, Context };
