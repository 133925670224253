import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import { red } from '@material-ui/core/colors';
import { PropTypes } from 'prop-types';

const RequestGadget = ({ data, ...props }) => (
  <Card
    sx={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: '' }}
      >
        <Grid item>

          <HighlightOffSharpIcon
            sx={{
              color: red[700],
              height: 56,
              width: 56
            }}
          />
        </Grid>
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Solicitudes no admitidas
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {data.quantity}
            {' '}
            solicitudes
          </Typography>
        </Grid>

      </Grid>
    </CardContent>
  </Card>
);

export default RequestGadget;

RequestGadget.propTypes = {
  data: PropTypes.object
};
