import checkoutFormModel from './checkoutFormModel';

const {
  formField: {
    firstName,
    surName,
    docId,
    cellphone,
    email,
    fax,
    address,
    anonymous,
    type,
    specialType,
    problem,
    inspectinoAddress,
    fincaNum,
    fincaOwner,
    file,
    patent,
    patentName,
    authorization
  }
} = checkoutFormModel;

export default {
  [firstName.name]: '',
  [surName.name]: '',
  [docId.name]: '',
  [cellphone.name]: '',
  [email.name]: '',
  [fax.name]: '',
  [address.name]: '',
  [anonymous.name]: false,
  [type.name]: '',
  [specialType.name]: '',
  [problem.name]: '',
  [inspectinoAddress.name]: '',
  [fincaNum.name]: '',
  [fincaOwner.name]: '',
  [file.name]: '',
  [patent.name]: '',
  [patentName.name]: '',
  [authorization.name]: false,
};
