import React from 'react';
import PropTypes from 'prop-types';
import {
  // Box,
  Button,
  // Container,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';

function RequestHomeButton({ inverted, type }) {
  const buttonInvertedTxt = () => (
    <div>
      <Typography variant="h5" align="center" color="text.secondary">
        En cambio también puedes
      </Typography>
    </div>
  );

  const buttonCreateReq = () => (
    <div>
      <Button
        sx={{ maxWidth: 'md' }}
        variant="outlined"
        color="primary"
        component={Link}
        to="/solicitudes"
      >
        Crear nueva solicitud de inspección
      </Button>
    </div>
  );

  const buttonViewReq = () => (
    <div>
      <Button
        sx={{ maxWidth: 'md' }}
        variant="outlined"
        color="primary"
        component={Link}
        to="/ver-solicitud"
      >
        Ver Estado de Solicitud ya realizada
      </Button>
    </div>
  );

  if (type === 'CREATE') {
    return (
      <>
        {inverted && buttonInvertedTxt()}
        {inverted && buttonViewReq()}
        {!inverted && buttonCreateReq()}
      </>
    );
  } if (type === 'VIEW') {
    return (
      <>
        {inverted && buttonInvertedTxt()}
        {inverted && buttonCreateReq()}
        {!inverted && buttonViewReq()}
      </>
    );
  }
  return <></>;
}

RequestHomeButton.propTypes = {
  type: PropTypes.string,
  inverted: PropTypes.bool
};

export default RequestHomeButton;
