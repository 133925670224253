import React from 'react';
import {
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RequestDataInput from './RequestDataInput';

const ConfirmationUserForm = ({ formValues }) => (
  <>
    <Typography variant="h4" gutterBottom>
      Información del solicitante
    </Typography>
    {formValues.firstName && <RequestDataInput name="Nombre" data={formValues.firstName} key="nombre" />}
    {formValues.surName && <RequestDataInput name="Apellidos" data={formValues.surName} key="Apellidos" />}
    {formValues.docId && <RequestDataInput name="Identificación" data={formValues.docId} key="id" />}
    {formValues.cellphone && <RequestDataInput name="Número de teléfono" data={formValues.cellphone} key="número" />}
    {formValues.email && <RequestDataInput name="Correo" data={formValues.email} key="correo" />}
    {formValues.fax !== 0 && formValues.fax && <RequestDataInput name="Fax" data={formValues.fax} key="fax" />}
    {formValues.address && <RequestDataInput name="Dirección" data={formValues.address} key="dirreción" />}

  </>
);

export default ConfirmationUserForm;

ConfirmationUserForm.propTypes = {
  formValues: PropTypes.object,
};
