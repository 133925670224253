import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  FileText as FileTextIcon,
  Hash as HashIcon,
  /*
  AlertCircle as AlertCircleIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  Users as UsersIcon,
  UserPlus as UserPlusIcon,
  */
  ArrowRightCircle as ArrowRightCircleIcon,
  Eye as EyeIcon,
  XCircle as XCircleIcon,
} from 'react-feather';
import GradingIcon from '@mui/icons-material/Grading';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import NavItem from './NavItem';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Inicio'
  },
  {
    href: '/app/sol-entrantes',
    icon: ArrowRightCircleIcon,
    title: 'Solicitudes Entrantes'
  },
  {
    href: '/app/sol-admitidas',
    icon: GradingIcon,
    title: 'Solicitudes Admitidas'
  },
  {
    href: '/app/sol-inspeccion',
    icon: EyeIcon,
    title: 'Solicitudes en Inspección'
  },
  {
    href: '/app/sol-rechazadas',
    icon: XCircleIcon,
    title: 'Solicitudes no admitidas'
  },
  {
    href: '/app/sol-finalizadas',
    icon: DoneAllIcon,
    title: 'Solicitudes finalizadas'
  },
  {
    href: '/app/usuarios',
    icon: PersonAddAltIcon,
    title: 'Usuarios'
  },
  /* {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers'
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Products'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  }, */
  /* {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register'
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error'
  }, */
  {
    href: '/solicitudes',
    icon: FileTextIcon,
    title: 'Solicitud'
  },
  {
    href: '/ver-solicitud',
    icon: HashIcon,
    title: 'Ver Solicitud'
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Typography
          color="textPrimary"
          variant="h5"
        >
          Administración
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          Municipalidad Mora
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden xlUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xlDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
