import axios from 'axios';
import util from './utilsRequest';

export default async function getRequestInspectionInfoServ(code) {
  const { headers, URL } = util;
  const config = {
    headers,
  };

  try {
    const res = await axios.get(
      `${URL}/api/v1/inspections_register/details/${code}/`,
      config
    );
    if (res.status !== 200) throw new Error(res);
    return { ok: true, info: res.data };
  } catch (e) {
    if (e.response.status === 404) return { ok: false, info: 'Inspección no existe' };
    throw new Error(e);
  }
}
