import { Link as RouterLink, Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import useAuthUser from 'src/hooks/useAuthUser';
import Logo from './Logo';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const location = useLocation();
  const {
    logout
  } = useAuthUser();

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        {
          !location.pathname.includes('/app') && (
            <Button
              color="inherit"
              size="large"
              variant="outlined"
              startIcon={<QueryStatsIcon />}
              component={Link}
              to="/app/dashboard"
            >
              Inicio
            </Button>
          )
        }

        <Button
          sx={{ borderRadius: '20px', marginLeft: '1em' }}
          color="inherit"
          variant="outlined"
          size="large"
          component={Link}
          onClick={logout}
          to="/login"
        >
          <InputIcon />
        </Button>

        <Hidden xlUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
