import { Doughnut } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme,
  Grid
} from '@material-ui/core';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import useAuthUser from 'src/hooks/useAuthUser';
import getDashboardInfoMonth from 'src/services/getDashboardInfoMonth';

const requestStatus = [
  { name: 'Entrantes', color: '#5664d2' },
  { name: 'Admitidas', color: colors.green[600] },
  { name: 'En Inspección', color: colors.orange[700] },
  { name: 'No admitidas', color: colors.red[700] },
];

const RequestPie = (props) => {
  const theme = useTheme();
  const { token } = useAuthUser();
  const [dataList, setDataList] = useState([0, 0, 0, 0]);
  const data = {
    datasets: [
      {
        data: dataList,
        backgroundColor: [
          requestStatus[0].color,
          requestStatus[1].color,
          requestStatus[2].color,
          requestStatus[3].color
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: [requestStatus[0].name, requestStatus[1].name, requestStatus[2].name, requestStatus[3].name]
  };

  const calculate = (value) => {
    if (dataList.length > 0 && value) {
      let sum = 0;
      for (let i = 0; i < dataList.length; i++) {
        sum += dataList[i];
      }
      return ((value / sum) * 100).toFixed(2);
    }
    return 0;
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    },
  };

  const requestsTypes = [
    {
      title: requestStatus[0].name,
      value: calculate(dataList[0]),
      icon: ArrowCircleRightOutlinedIcon,
      color: requestStatus[0].color
    },
    {
      title: requestStatus[1].name,
      value: calculate(dataList[1]),
      icon: TaskAltIcon,
      color: requestStatus[1].color
    },
    {
      title: requestStatus[2].name,
      value: calculate(dataList[2]),
      icon: VisibilityOutlinedIcon,
      color: requestStatus[2].color
    },
    {
      title: requestStatus[3].name,
      value: calculate(dataList[3]),
      icon: HighlightOffSharpIcon,
      color: requestStatus[3].color
    },
  ];

  const getDataList = (dataRequest) => {
    const requestTypes = [
      'Entrantes',
      'Aprobadas',
      'En inspeccion',
      'No aprobada'
    ];
    const dataInsList = [];
    for (let i = 0; i < 4; i++) {
      const dataType = dataRequest.filter((dt) => dt.status === requestTypes[i]);
      if (dataType.length === 0) dataInsList.push(0);
      else dataInsList.push(dataType[0].quantity);
    }
    setDataList(dataInsList);
  };

  useEffect(() => {
    getDashboardInfoMonth(token).then((response) => {
      if (response.ok) {
        getDataList(response.info);
      }
    });
  }, []);

  return (
    <Card {...props}>
      <CardHeader title="Solicitudes Mes actual" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {requestsTypes.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Grid item lg={6} key={title}>
              <Box
                sx={{
                  p: 1,
                  textAlign: 'center'
                }}
              >
                <Icon color="action" />
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  {title}
                </Typography>
                <Typography
                  style={{ color }}
                  variant="h2"
                >
                  {value}
                  %
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RequestPie;
