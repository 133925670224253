import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Typography } from '@material-ui/core';
import status from 'src/utils/status';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#8892E5'
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#8892E5'
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#5664d2',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#8892E5'

  }),
}));

function ColorlibStepIcon({
  icon, active, completed, className
}) {
  const icons = {
    1: <ArrowCircleRightOutlinedIcon />,
    2: <SettingsIcon />,
    3: <VisibilityOutlinedIcon />,
    4: <DoneAllIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function CustomizedSteppers(props) {
  let steps = ['Solicitud Recibida', 'Control de Solicitud', 'Inspección en Proceso', 'Finalizado'];
  const { step } = { ...props };
  let info = '';
  if (status[step] > 0) {
    info = 'Admitido';
    if (status[step] === 1.5) {
      info = 'No Admitido';
      steps = ['Solicitud Recibida', 'Control de Solicitud'];
    }
  }
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={status[step]} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {label}
              {' '}
              {
                label === 'Control de Solicitud'
                  ? (
                    <Typography color="primary">{info}</Typography>
                  )
                  : ''
              }
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
