import React from 'react';
import {
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function RequestDataInput({ name, data, extended }) {
  return (
    <div style={extended ? { margin: '5px 0px' } : { display: 'flex', margin: '5px 0px' }}>
      <Typography
        color="textPrimary"
        variant="h6"
        style={{ marginRight: 5, fontWeight: 500 }}
      >
        {name}
        :
      </Typography>
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {data}
      </Typography>
    </div>
  );
}

RequestDataInput.propTypes = {
  name: PropTypes.string,
  data: PropTypes.any,
  extended: PropTypes.bool
};
