import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';

const {
  formField: {
    firstName,
    surName,
    docId,
    cellphone,
    email,
    fax,
    address,
    anonymous,
    type,
    specialType,
    problem,
    inspectinoAddress,
    fincaNum,
    fincaOwner,
    file,
    patent,
    patentName,
    authorization,
  }
} = checkoutFormModel;

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [surName.name]: Yup.string().required(`${surName.requiredErrorMsg}`),
    [docId.name]: Yup.string().required(`${docId.requiredErrorMsg}`),
    [cellphone.name]: Yup.string().required(`${email.requiredErrorMsg}`),
    [email.name]: Yup.string().email(`${email.invalidErrorMsg}`).required(`${email.requiredErrorMsg}`),
    [fax.name]: Yup.string(),
    [address.name]: Yup.string(),
    [anonymous.name]: Yup.boolean(),
  }),
  Yup.object().shape({
    [type.name]: Yup.string().required(`${type.requiredErrorMsg}`),
    [specialType.name]: Yup.string().when('type', {
      is: 100,
      then: Yup.string().required(`${specialType.requiredErrorMsg}`)
    }),
  }),
  Yup.object().shape({
    [problem.name]: Yup.string().required(`${problem.requiredErrorMsg}`),
    [inspectinoAddress.name]: Yup.string().required(`${inspectinoAddress.requiredErrorMsg}`),
    [fincaNum.name]: Yup.number(),
    [fincaOwner.name]: Yup.string(),
    [file.name]: Yup.mixed(),
    [patent.name]: Yup.string(),
    [patentName.name]: Yup.string(),
    [authorization.name]: Yup.boolean(),
  }),
];
