import {
  Box,
  CardHeader,
  Card,
  CardContent,
  Divider,
  Typography,
  Button,
  Grid,
  TextField
} from '@material-ui/core';
// import dataExample from 'src/utils/dataExample';
import RequestIcon from 'src/components/RequestIcon/RequestIcon';
import TYPES from 'src/components/RequestIcon/TYPES';
import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

const AccountProfile = ({ type, actionRequest, ...props }) => {
  const { id, name } = type;
  const [disabled, setDisabled] = useState(false);
  const takeActionButton = (newStatusId, inspectorName, resolution) => {
    setDisabled(true);
    if (newStatusId === 4) actionRequest(newStatusId, '', resolution);
    else if (newStatusId === 3) actionRequest(newStatusId, inspectorName);
    else if (newStatusId === 5) actionRequest(newStatusId, '', resolution);
    else { actionRequest(newStatusId); }
  };

  const IncommingRequestControl = () => {
    const [resolution, setResolution] = useState('');
    const invalidResolution = () => {
      if (resolution === '') return true;
      if (resolution === null) return true;
      return false;
    };

    return (
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={12}
          mt={1}
        >
          <Typography
            color="text.primary"
            gutterBottom
            variant="h5"
            sx={{ textAlign: 'center', fontWeight: 400 }}
          >
            En el caso de no cumplir los requisitos adecuados:
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
        >
          <TextField
            multiline
            rows={4}
            fullWidth
            label="Resolución final de la inspección no admitida"
            variant="outlined"
            value={resolution}
            onChange={(e) => setResolution(e.target.value)}
          />
        </Grid>
        <Grid
          item
          lg={12}
        >
          <Button fullWidth disabled={disabled || invalidResolution(resolution)} onClick={() => takeActionButton(4, '', resolution)} variant="outlined">No Admitir</Button>
        </Grid>
        <Grid
          item
          lg={12}
        >
          <Typography
            color="text.primary"
            gutterBottom
            variant="h5"
            sx={{ textAlign: 'center', fontWeight: 400 }}
          >
            De lo contrario:
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
        >
          <Button fullWidth variant="outlined" disabled={disabled} onClick={() => takeActionButton(2)}>Admitir</Button>
        </Grid>
      </Grid>
    );
  };

  const ApprovedRequestControl = () => {
    const [inspector, setInspector] = useState('');
    const invalidInspector = () => {
      if (inspector === '') return true;
      if (inspector === null) return true;
      return false;
    };

    return (
      <>
        <TextField
          sx={{ paddingBottom: '1em' }}
          fullWidth
          label="Nombre del Inspector"
          variant="outlined"
          value={inspector}
          onChange={(e) => setInspector(e.target.value)}
        />
        <Button fullWidth disabled={disabled || invalidInspector(inspector)} onClick={() => takeActionButton(3, inspector)} variant="outlined">Pasar a Inspección</Button>
      </>
    );
  };

  const InspectionRequestControl = () => {
    const [resolution, setResolution] = useState('');
    const invalidResolution = () => {
      if (resolution === '') return true;
      if (resolution === null) return true;
      return false;
    };
    return (
      <>
        <TextField
          multiline
          sx={{ paddingBottom: '1em' }}
          rows={4}
          fullWidth
          label="Resolución final de la inspección"
          variant="outlined"
          value={resolution}
          onChange={(e) => setResolution(e.target.value)}
        />
        <Button fullWidth variant="outlined" disabled={disabled || invalidResolution(resolution)} onClick={() => takeActionButton(5, '', resolution)}>Dar por Finalizado</Button>
      </>
    );
  };

  useEffect(() => {
    setDisabled(false);
  }, [type]);
  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >

          <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            Estado de Solicitud
          </Typography>
          <RequestIcon
            type={TYPES[id - 1]}
            height={100}
            width={100}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {name}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      {id < 4 && (
      <>
        <CardHeader
          title="Acciones de Control"
        />
        <Divider />
        <CardContent
          sx={{
            padding: 3
          }}
        >
          {id === 1 && <IncommingRequestControl />}
          {id === 2 && <ApprovedRequestControl />}
          {id === 3 && <InspectionRequestControl />}

        </CardContent>
      </>
      )}

    </Card>
  );
};

AccountProfile.propTypes = {
  type: PropTypes.any,
  actionRequest: PropTypes.func
};

export default AccountProfile;
