import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import downloadInspectionFileServ from 'src/services/downloadInspectionFileServ';
import useAuthUser from 'src/hooks/useAuthUser';
import getDashboardInfoAllMonthsServ from 'src/services/getDashboardInfoAllMonths';

const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];

const RequestsReview = (props) => {
  const [dataValues, setDataValues] = useState([]);
  const { token } = useAuthUser();
  const theme = useTheme();
  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: dataValues,
        label: 'Total de Solicitudes por mes del año Actual',
        maxBarThickness: 10
      },
    ],
    labels: MONTHS
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        ticks: {
          fontColor: theme.palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }

    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const getDataList = (allMonthData) => {
    const dataList = [];
    if (allMonthData.length > 0) {
      for (let i = 1; i < 13; i++) {
        const monthObject = allMonthData.filter((dataElement) => dataElement.month === i);
        if (monthObject.length === 1) {
          dataList.push(monthObject[0].quantity);
        } else {
          dataList.push(0);
        }
      }
      setDataValues(dataList);
    } else {
      setDataValues([]);
    }
  };

  useEffect(() => {
    getDashboardInfoAllMonthsServ(token).then((response) => {
      if (response.ok) { getDataList(response.info); }
    });
  }, []);

  return (
    <Card {...props}>
      <CardHeader
        action={(
          <>
            {
              /*
              <Button
              endIcon={<ArrowDropDownIcon />}
              size="small"
              variant="text"
            >
              Hace 6 Meses
            </Button>

              */
            }
            <Button
              endIcon={<DownloadIcon />}
              size="small"
              variant="text"
              sx={{ marginRight: '2em' }}
              onClick={() => downloadInspectionFileServ(token)}
            >
              Descargar Excel
            </Button>

          </>

        )}
        title="Total Solicitudes"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 500,
            position: 'relative'
          }}
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        {
/*
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button> */
        }
      </Box>
    </Card>
  );
};

export default RequestsReview;
