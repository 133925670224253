import { useState, useEffect } from 'react';
import useAuthUser from 'src/hooks/useAuthUser';
import getRequestInspectionInfoAdminServ from 'src/services/requestInspectionInfoAdminServ';
// import PropTypes from 'prop-types'

function calculatePrevPageEnabled(page) {
  if (page === 1) { return false; }
  return true;
}

function calculateNextPageEnabled(page, max) {
  if (page < max) { return true; }
  return false;
}

function useRequests({ requestType }) {
  const { token } = useAuthUser();
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const size = 10;
  const max = Math.ceil(total / size);
  const prevPageEnabled = calculatePrevPageEnabled(page);
  const nextPageEnabled = calculateNextPageEnabled(page, max);

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    setRequests([]);
    getRequestInspectionInfoAdminServ(requestType, page, token)
      .then((response) => {
        setRequests(response.items);
        setTotal(response.total);
      })
      .catch((err) => {
        console.log(`Error en la petición: \n${err}`);
        console.log(err);
      });
  }, [page]);

  return {
    requests,
    page,
    size,
    max,
    prevPageEnabled,
    nextPageEnabled,
    previousPage,
    nextPage,
  };
}

// useRequests.propTypes = {}

export default useRequests;
