import {
  // Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  // Container
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RequestIcon from '../RequestIcon/RequestIcon';

const types = {
  incomming: { title: 'Entrantes' },
  approved: { title: 'Admitida' },
  inspected: { title: 'en Inspección' },
  rejected: { title: 'No admitida' },
  completed: { title: 'Finalizada' }
};

const RequestHeader = ({
  type, total, growth, ...props
}) => (
  <Card
    sx={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: '', alignItems: 'center' }}
      >
        <Grid item lg={9}>
          <Box
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <RequestIcon
              type={type}
              height={56}
              width={56}
            />
            <Typography
              noWrap
              color="text.primary"
              gutterBottom
              variant="h2"
              mt={1}
              ml={2}
            >
              Solicitudes
              {' '}
              {types[type].title}
            </Typography>
          </Box>
        </Grid>
        {/*
          <Grid item lg={3}>
            <Box
              mr={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end'
              }}
            >
              <Typography
                color="textPrimary"
                variant="h3"
                sx={{

                }}
              >
                {total}
                {' Solicitudes '}
                {types[type].title}
              </Typography>
            </Box>
          </Grid>

        */}
      </Grid>
    </CardContent>
  </Card>
);

export default RequestHeader;

RequestHeader.propTypes = {
  type: PropTypes.oneOf([
    'incomming',
    'approved',
    'inspected',
    'rejected',
    'completed'
  ]),
  total: PropTypes.number,
  growth: PropTypes.number
};
