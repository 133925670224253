import React from 'react';
import PropTypes from 'prop-types';
import RequestDataInput from '../InspectionRequest/Forms/ConfirmationFormSections/RequestDataInput';

const headerTranslate = {
  id: 'ID',
  ref: 'Nro Trámite',
  last_status_date: 'Fecha de modificación de estado',
  status: 'Estado',
  inspector_name: 'Nombre del Inspector',
  resolution: 'Resolución'
};

const ConfirmationUserForm = ({ formValues }) => (
  <>

    {formValues.id && <RequestDataInput name={headerTranslate.id} data={formValues.id} key="id" />}
    {formValues.ref && <RequestDataInput name={headerTranslate.ref} data={formValues.ref} key="ref" />}
    {formValues.last_status_date && <RequestDataInput name={headerTranslate.last_status_date} data={formValues.last_status_date} key="status_date" />}
    {formValues.status && <RequestDataInput name={headerTranslate.status} data={formValues.status} key="status" />}
    {formValues.status === 'En inspeccion' && <RequestDataInput name={headerTranslate.inspector_name} data={formValues.inspector_name} key="inspector_name" />}
    {formValues.status === 'finalizada' && <RequestDataInput name={headerTranslate.inspector_name} data={formValues.inspector_name} key="inspector_name" />}
    {formValues.resolution !== 'WITHOUT RESOLUTION' && <RequestDataInput name={headerTranslate.resolution} data={formValues.resolution} key="resolution" />}
  </>
);

export default ConfirmationUserForm;

ConfirmationUserForm.propTypes = {
  formValues: PropTypes.object,
};
