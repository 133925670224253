import React from 'react';
import { useField } from 'formik';
import {
  Box,
  Container,
  Grid,
  Typography,
//  Button
} from '@material-ui/core';
import SelectField from 'src/components/FormFiled.jsx/SelectField';
import PropTypes from 'prop-types';
import InputField from 'src/components/FormFiled.jsx/InputField';
import { ListItemText, ListItem, List } from '@mui/material';
import inspections from 'src/utils/inspections';
// import DownloadIcon from '@mui/icons-material/Download';

export default function InspectionDataForm({ formField }) {
  const { typeOption, requirements, typesList } = inspections;
  const {
    type,
    specialType,
    // anonymous
  } = formField;

  const [typeField] = useField(type.name);

  /*
  const [anonymousField] = useField(anonymous.name);
  const getTypesfilter = () => {
    if (anonymousField.value) {
      return typeOption.filter((t) => t.req === 2);
    }
    return typeOption;
  };
  */

  const getInspectionType = () => typeOption.filter((t) => typeField.value === t.value);

  const showDesc = (typeField.value !== typesList[typesList.length - 1]) && (typeField.value !== '');

  const renderReqInfo = () => {
    const { desc } = getInspectionType()[0];
    return (
      <>
        <Typography variant="h6" color="text.primary" paragraph sx={{ mt: 1 }}>
          {desc}
        </Typography>
      </>
    );
  };

  const renderRequirements = () => {
    const requirementsRange = getInspectionType()[0].req;
    const requireList = requirements.slice(0, requirementsRange);
    return requireList.map((r, i) => (
      <ListItem key={r} sx={{ p: 0, pl: 1 }}>
        <ListItemText
          primary={(
            <Typography variant="listItem" color="text.primary">
              {i + 1}
              {' '}
              -
              {' '}
              {r}
            </Typography>
              )}
        />
      </ListItem>
    ));
  };

  /*
  const renderDownloadPDF = () => {
    if (getInspectionType()[0].req > 6) {
      return (
        <Button variant="outlined" component="label" startIcon={<DownloadIcon />} sx={{ mt: 2 }}>
          Descargar Documento de Autorización
        </Button>
      );
    }
    return <></>;
  };
  */

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        pt: 2,
      }}
    >
      <Container maxWidth="sm">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <SelectField
              variant="standard"
              sx={{ mt: 1, minWidth: 120 }}
              name={type.name}
              label={type.label}
              data={typeOption}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            {
              typeField.value === typesList[typesList.length - 1] && (
                <InputField
                  fullWidth
                  margin="normal"
                  name={specialType.name}
                  label={specialType.label}
                  variant="outlined"
                  size="small"
                />
              )
            }
          </Grid>

        </Grid>
        {
          showDesc && (
            renderReqInfo()
          )
        }
        { typeField.value !== ''
          && (
          <>
            <Typography variant="h5" color="text.contrast" sx={{ mt: 1 }}>
              Requisitos para la inspección
            </Typography>

            <List>
              {renderRequirements()}
            </List>

          </>
          )}

      </Container>
    </Box>
  );
}

InspectionDataForm.propTypes = {
  formField: PropTypes.object,
};
