import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';
import ConfirmationUserForm from 'src/components/InspectionRequest/Forms/ConfirmationFormSections/ConfirmationUserForm';
import ConfirmationInspectionForm from 'src/components/InspectionRequest/Forms/ConfirmationFormSections/ConfirmationInspectionForm';
import RequestControlInfo from 'src/components/RequestDetail/RequestControlInfo';
import PropTypes from 'prop-types';

const AccountProfileDetails = ({ requestInfo, ...props }) => {
  const userRequestInfo = {
    firstName: requestInfo.applicant_name,
    surName: requestInfo.applicant_surname,
    docId: requestInfo.applicant_identification,
    cellphone: requestInfo.applicant_phonenum,
    email: requestInfo.applicant_email,
    fax: requestInfo.applicant_fax,
    address: requestInfo.address_applicant,
    type: requestInfo.inspection_type,
    problem: requestInfo.inspection_situation_problem,
    inspectinoAddress: requestInfo.address_inspection,
    id: requestInfo.inspection_id,
    ref: requestInfo.id_confirmation_code,
    last_status_date: requestInfo.last_status_date,
    status: requestInfo.inspection_status,
    inspector_name: requestInfo.inspector_name,
    fincaNum: requestInfo.estate_number,
    fincaOwner: requestInfo.estate_owner,
    patent: requestInfo.patent,
    patentName: requestInfo.patent_name,
    authorization: requestInfo.authorization,
    resolution: requestInfo.resolution
  };

  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          title="Detalles de la Solicitud"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{
              padding: 5,
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                marginTop: 2,
              }}
            >
              <ConfirmationUserForm formValues={userRequestInfo} />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <ConfirmationInspectionForm formValues={userRequestInfo} />
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <CardHeader
          title="Control de la Solicitud"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{
              padding: 5,
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            <Grid
              item
              xl={12}
              sx={{
                marginTop: 2,
              }}
            >

              <RequestControlInfo formValues={userRequestInfo} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        />
      </Card>
    </form>
  );
};

AccountProfileDetails.propTypes = {
  requestInfo: PropTypes.any
};
export default AccountProfileDetails;
