import { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import useAuthUser from 'src/hooks/useAuthUser';
import getDetailedRequestServ from 'src/services/getDetailedRequestServ';
import useLoading from 'src/hooks/useLoading';
import updateRequestStatusServ from 'src/services/updateRequestStatusServ';
import RequestControl from '../components/RequestDetail/RequestControl';
import RequestDetailSection from '../components/RequestDetail/RequestDetailSection';

const RequestDetail = () => {
  const [requestInfo, setRequestInfo] = useState({});
  const [needReload, setNeedReload] = useState(true);
  const { loading, setLoading, SkeletonLoading } = useLoading(true);
  const { id } = useParams();
  const { token } = useAuthUser();

  const actionRequest = useCallback((statusId, inspectorName, resolution) => {
    updateRequestStatusServ(requestInfo.inspection_id, statusId, token, inspectorName, resolution).then(
      (res) => {
        if (res.ok) {
          setNeedReload(true);
        }
      }
    );
  }, [requestInfo, token]);

  useEffect(() => {
    if (needReload) {
      getDetailedRequestServ(id, token)
        .then((response) => {
          setRequestInfo(response);
          setNeedReload(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(`Error en la petición: \n${err}`);
          console.log(err);
        });
    }
  }, [needReload]);
  if (loading) return (<></>);

  return (
    <>
      <Helmet>
        <title>Detalles Solicitud | Mora</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          {
            loading ? SkeletonLoading : (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <RequestControl type={{ id: requestInfo.id_status, name: requestInfo.inspection_status }} actionRequest={actionRequest} />

                </Grid>
                <Grid
                  item
                  lg={8}
                  md={6}
                  xs={12}
                >
                  <RequestDetailSection requestInfo={requestInfo} />

                </Grid>
              </Grid>
            )
          }

        </Container>
      </Box>
    </>
  );
};

export default RequestDetail;
