import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';
import registerService from 'src/services/registerService';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import useAuthUser from 'src/hooks/useAuthUser';

const Register = () => {
  const [renderAlert, setRenderAlert] = useState(false);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const { token } = useAuthUser();
  const alert = () => {
    if (response.ok) return <Alert severity="success">{response.info}</Alert>;
    return <Alert severity="error">{response.info}</Alert>;
  };

  return (
    <>
      <Helmet>
        <title>Registro | Mora</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: '',
              firstName: '',
              lastName: '',
              password: '',
            }}
            validationSchema={
              Yup.object().shape({
                username: Yup.string().max(255).required('Usuario es requerido'),
                firstName: Yup.string().max(255).required('El nombre es requerido'),
                lastName: Yup.string().max(255).required('Los apellidos son requerido'),
                password: Yup.string().max(255).required('La contraseña es requerida'),
              })
            }
            onSubmit={(values) => {
              setLoading(true);
              registerService(values, token).then((res) => {
                setResponse(res);
                setRenderAlert(true);
                setLoading(false);
              }).catch(() => {
                setResponse({ ok: false, info: 'Ha ocurrido un Error' });
                setRenderAlert(true);
                setLoading(false);
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Crear una cuenta de usuario
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    se creara una cuenta con un rol por defecto
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="Nombre"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Apellidos"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Usuario"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Crear Cuenta ahora
                  </Button>

                </Box>
                {renderAlert && alert()}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Register;
