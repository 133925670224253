import { Helmet } from 'react-helmet';
import {
  Grid,
} from '@material-ui/core';
import RequestInfo from 'src/components/InspectionRequest/RequestInfo';
import ViewRequestInfo from 'src/components/HomeMora/ViewRequestInfo';

const styles = {
  paperContainer: {
    backgroundImage: 'url("static/images/munis/background.jpg")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100vh'
  }
};

const RequestStatus = () => (
  <>
    <Helmet>
      <title>Ver Solicitud | Mora</title>
    </Helmet>
    <main style={{ overflow: 'hidden', height: '100%' }}>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ overflowY: 'auto', flexGrow: '1', height: '100%' }}
        >
          <ViewRequestInfo inverted />
          <RequestInfo />
        </Grid>
        <Grid
          item
          xs={0}
          lg={6}
          style={styles.paperContainer}
        />
      </Grid>
    </main>
  </>
);

export default RequestStatus;
