import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

function CheckBoxField(props) {
  const { label, noDisplay, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const display = !noDisplay;

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
    return <></>;
  };

  const onChange = (e) => {
    setValue(e.target.checked);
  };

  return (
    <>
      {
      display
      && (
        <FormControl {...rest}>
          <FormControlLabel
            value={field.value}
            checked={field.value}
            control={<Checkbox {...field} onChange={onChange} />}
            label={label}
          />
          {renderHelperText()}
        </FormControl>
      )
    }
    </>

  );
}

CheckBoxField.propTypes = {
  label: PropTypes.string.isRequired,
  noDisplay: PropTypes.bool
};

export default CheckBoxField;
