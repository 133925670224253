import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import { Formik, Form } from 'formik';
import createAxiosInspectionRequestServ from 'src/services/createInpectionRequestServ';
import UserDataForm from './Forms/UserDataForm';
import InspectionDataForm from './Forms/InspectionDataForm';
import ConfirmationForm from './Forms/ConfirmationForm';
import RequirementDataForm from './Forms/RequirementDataForm';
import validationSchema from './FormModel/validationSchema';
import checkoutFormModel from './FormModel/checkoutFormModel';
import formInitialValues from './FormModel/formInitialValues';

const { formId, formField } = checkoutFormModel;
const steps = ['Datos del solicitante', 'Datos de inspección', 'Requisitos', 'Confirmación'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <UserDataForm formField={formField} />;
    case 1:
      return <InspectionDataForm formField={formField} />;
    case 2:
      return <RequirementDataForm formField={formField} />;
    case 3:
      return <ConfirmationForm />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Checkout() {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const [confirmationCode, setConfirmationCode] = useState('');
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = (values, actions) => {
    if (activeStep === steps.length - 1) {
      setActiveStep(activeStep + 1);
      createAxiosInspectionRequestServ(values)
        .then((response) => {
          setConfirmationCode(response);
        })
        .catch((err) => {
          console.log(`Error en la petición: \n${err}`);
          console.log(err);
        });
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
    return true;
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Box sx={{ mb: 3 }}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Nueva solicitud de inspección
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Rellena el formulario y envía tu solicitud de inspección
            </Typography>
          </Box>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 0 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <>
            {activeStep === steps.length ? (
              <>
                <Alert style={{ marginTop: 30, marginBottom: 10 }} severity="success">Tu solicitud ha sido enviada correctamente</Alert>
                <Typography
                  color="textPrimary"
                  variant="h6"
                  style={{ paddingTop: 0 }}
                >
                  Número de trámite
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  {confirmationCode || <Skeleton sx={{ bgcolor: '#95bcff2A', borderRadius: '10px' }} height={50} variant="text" />}
                </Typography>

                <Typography
                  style={{ paddingTop: 20 }}
                  color="textSecondary"
                  gutterBottom
                  variant="subtitle1"
                >
                  En caso de haber brindado un correo electrónico se le enviará el código de solicitud y un link para ver el estado de la solicitud. De lo contrario es aconsejable que guarde el número de solicitud e ingrese a la plataforma para encontrar su solicitud.
                </Typography>
                <Alert style={{ marginTop: 20 }} severity="info">  Guarda el anterior código para mantenerte actualizado en el estado de tu solicitud</Alert>
              </>
            ) : (
              <Formik
                initialValues={formInitialValues}
                validationSchema={currentValidationSchema}
                onSubmit={handleSubmit}
              >
                <Form id={formId}>
                  {getStepContent(activeStep)}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Atrás
                      </Button>
                    )}

                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1 ? 'Enviar Solicitud' : 'Siguiente'}
                    </Button>
                  </Box>
                </Form>
              </Formik>
            )}
          </>
        </Paper>
      </Container>
    </>
  );
}
