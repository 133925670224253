import { React } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import InputField from 'src/components/FormFiled.jsx/InputField';
import CheckBoxField from 'src/components/FormFiled.jsx/CheckBoxField';

export default function UserDataForm({ formField }) {
  const {
    firstName,
    surName,
    docId,
    cellphone,
    email,
    fax,
    address,
    anonymous,
  } = formField;

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        pt: 2,
      }}
    >
      <Container maxWidth="sm">

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <InputField
              fullWidth
              name={firstName.name}
              label={firstName.label}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <InputField
              fullWidth
              name={surName.name}
              label={surName.label}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <InputField
              fullWidth
              name={docId.name}
              label={docId.label}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <InputField
              fullWidth
              name={fax.name}
              label={fax.label}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            pb: 1,
            pt: 3
          }}
        >
          <Typography
            align="center"
            color="textSecondary"
          >
            Lugar o medio para recibir notificaciones
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <InputField
              fullWidth
              name={email.name}
              label={email.label}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >

            <InputField
              fullWidth
              name={cellphone.name}
              label={cellphone.label}
            />
          </Grid>
        </Grid>
        <InputField
          fullWidth
          name={address.name}
          label={address.label}
          rows={4}
          multiline
        />

        <CheckBoxField
          name={anonymous.name}
          label={anonymous.label}
        />

      </Container>
    </Box>
  );
}

UserDataForm.propTypes = {
  formField: PropTypes.object,
};
