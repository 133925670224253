const URL = 'https://simplemunibackend.neuralcoders.com';
// const URL = 'http://127.0.0.1:8000';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};

const getHeaderWithAuth = (token) => ({ ...headers, Authorization: `Bearer ${token}` });

export default { headers, URL, getHeaderWithAuth };
