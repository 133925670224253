import { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
// import PropTypes from 'prop-types'

function useLoading(defaultValue) {
  const [loading, setLoading] = useState(defaultValue);

  const SkeletonLoading = () => {
    const sx = { bgcolor: '#95bcff2A', borderRadius: '10px' };

    return (
      <Stack spacing={2}>
        <Skeleton sx={sx} variant="rectangular" height={200} />
        <Skeleton sx={sx} variant="rectangular" height={200} />
        <Skeleton sx={sx} variant="rectangular" height={200} />
      </Stack>
    );
  };
  return {
    loading,
    setLoading,
    SkeletonLoading
  };
}

export default useLoading;
