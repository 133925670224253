import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import CustomerList from './pages/CustomerList';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ProductList from './pages/ProductList';
import Register from './pages/Register';
import Settings from './pages/Settings';
import MoraRequest from './pages/MoraRequest';
import RequestStatus from './pages/RequestStatus';
import RequestTracking from './pages/RequestTracking';
import IncomingReq from './pages/IncommingReq';
import ApprovedReq from './pages/ApprovedReq';
import RejectedRed from './pages/RejectedReq';
import CompletedReq from './pages/CompletedReq';
import InspectionReq from './pages/InspectionReq';
import RequestDetail from './pages/RequestDetail';
// import Home from './pages/Home';

const routes = (isLogged) => ([

  {
    path: 'app',
    element: isLogged ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'sol-entrantes', element: <IncomingReq /> },
      { path: 'sol-admitidas', element: <ApprovedReq /> },
      { path: 'sol-inspeccion', element: <InspectionReq /> },
      { path: 'sol-rechazadas', element: <RejectedRed /> },
      { path: 'sol-finalizadas', element: <CompletedReq /> },
      { path: 'sol-detalles/:id', element: <RequestDetail /> },
      { path: 'usuarios', element: <Register /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'solicitudes', element: <MoraRequest /> },
      { path: 'ver-solicitud', element: <RequestStatus /> },
      { path: 'solicitud-track/:code', element: <RequestTracking /> },
      { path: 'inicio', element: <RequestStatus /> },
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/inicio" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
]);

export default routes;
