import {
  // Avatar,
  // Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
// import { green } from '@material-ui/core/colors';
import { PropTypes } from 'prop-types';

const RequestGadget = ({ data, ...props }) => (
  <Card
    sx={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: '' }}
      >
        <Grid item>

          <ArrowCircleRightOutlinedIcon
            sx={{
              color: '#5664d2',
              height: 56,
              width: 56
            }}
          />
        </Grid>

        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Solicitudes Entrantes
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {data.quantity}
            {' '}
            solicitudes
          </Typography>
        </Grid>

      </Grid>
      {/*
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ArrowUpwardIcon sx={{ color: green[600] }} />
          <Typography
            sx={{
              color: green[600],
              mr: 1
            }}
            variant="body2"
          >
            12%
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Desde la última semana
          </Typography>
        </Box>
      */}
    </CardContent>
  </Card>
);

export default RequestGadget;

RequestGadget.propTypes = {
  data: PropTypes.object
};
