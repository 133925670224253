import { useCallback, useContext, useState } from 'react';
import Context from 'src/context/AuthContext';
import loginService from 'src/services/loginService';

// TODO: Realizar validación del token cada vez que el token cambie o sea requerido
export default function useUser() {
  const { token, setToken } = useContext(Context.Context);
  const [state, setState] = useState({ loading: false, error: false });

  const login = useCallback((username, password) => {
    setState({ loading: true, error: false });
    loginService(username, password)
      .then((response) => {
        if (response.ok) {
          const newToken = response.info.access_token;
          window.sessionStorage.setItem('token', newToken);
          setState({ loading: false, error: false });
          setToken(newToken);
        } else if (response.info === 0 || response.info === 1) {
          window.sessionStorage.removeItem('token');
          setState({ loading: false, error: true });
        }
      })
      .catch((err) => {
        window.sessionStorage.removeItem('token');
        setState({ loading: false, error: true });
        console.error(err);
      });
  }, [setToken]);

  const logout = useCallback(() => {
    window.sessionStorage.removeItem('token');
    setToken(null);
  }, [setToken]);

  return {
    token,
    isLogged: Boolean(token),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    login,
    logout
  };
}
